import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Public Transport App Template | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/public-transport-mobile-app/"
    metaDescription="Introducing Citymesh, our public transport mobile app design template. Simply open the template and tweak to suit your own project!"
    description="
    h2:Introducing Citymesh, Uizard's public transport mobile app design template
    <br/>
    Say hello to Citymesh, our public transport mobile app design template, the perfect solution for your app design project. Citymesh comes with everything you need to get your own design built out and, with Uizard's smart sharing features, you can <a:https://uizard.io/prototyping/>UI prototype</a> with your team rapidly in real time.
    <br/>
    h3:Map out your own stunning public transport app design in minutes
    <br/>
    Want to get to your destination quickly? Well, you'll be happy to hear that Uizard is all about speed. With our stunning transport app template, you can have your own design ready in minutes. Simply sign up to Uizard, open the template, and get to work.
    <br/>
    h3:Customization made easy
    <br/>
    All our <a:https://uizard.io/templates/>UI templates</a> are ready to go straight out of the box however, we get the impression you want to make the design your own, right? Uizard is packed with smart customization features, meaning you can use our templates as they come or you can tweak, change, adapt, and iterate our templates to meet your own goals.
    "
    pages={[
      "App sign up and log in pages",
      "A sleek and simple nav footer bar linking to all the design's core screens",
      "A wallet page demonstrating ticket storage and cash top up functionality",
      "A profile page with all the necessary form data fields",
    ]}
    projectCode="AoYXAb04evcqnm01xb8o"
    img1={data.image1.childImageSharp}
    img1alt="transport app design template overview"
    img2={data.image2.childImageSharp}
    img2alt="transport app design template trip screen"
    img3={data.image3.childImageSharp}
    img3alt="transport app design template ticket screen"
    img4={data.image4.childImageSharp}
    img4alt="transport app design template search screen"
    img5={data.image5.childImageSharp}
    img5alt="transport app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/public-transport-mobile-app/transport-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/public-transport-mobile-app/transport-app-trip.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/public-transport-mobile-app/transport-app-ticket.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/public-transport-mobile-app/transport-app-search.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/public-transport-mobile-app/transport-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
